import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandlerApp from "../utils/handlerApp";
import axios from "axios";
// import env from "../env-local";
import { CircularProgress } from "@mui/material";
import Check from "../assets/img/check.svg";

const ViewTransaction = () => {
    const endpoint = 'transactions/check_invoice'
    const { id } = useParams();
    const [t, i18n] = useTranslation("global");
    const [loader, setLoader] = useState(false);
    // const api = useFetch();
    // const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [card, setCard] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const instance = axios.create({
        baseURL: process.env.REACT_APP_URL_BACKEND,
    })

    const formatCurrency = (value) => {
        let formatting_options = {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }
        let dollarString = new Intl.NumberFormat("en-US", formatting_options);
        return dollarString.format(value);
    }

    const handleDownloadPDF = async () => {
        try {
            setLoader(true);
            instance.get(endpoint + '/download/' + id, {
                responseType: 'blob',
            })
            .then(response => {
                        setLoader(false);
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Invoice_${data.tx_code}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                })
            } catch (error) {
                console.error('Error al descargar el PDF:', error);
            }
    };


    useEffect(() => {
        setLoader(true);
        instance.get(endpoint + '/' + id)
            .then(response => {
                if (response.data.success) {
                    setLoader(false);
                    let record = response.data.data[0]
                    setData(record)
                    setCard(JSON.parse(record.tx_card));
                    setTitle('Transaction: ' + record.tx_code)

                } else {
                    setLoader(false);
                }
            })
            .catch(error => {
                setLoader(false);
            })
    }, []);

    return (
        <div className="d-flex justify-content-center align-items-center container-result">
            <div className="d-flex flex-column justify-content-center align-items-center container-result-transaction">
                <span className="check" style={{top: data.tx_method == 'CASH'&&'9vh'}}>
                    <img className="bold-image" src={Check} alt={'check'} />
                </span>
                <div className="bold-container-content result-transaction">
                    <div className="title-section-forms mt-3">{t('transaction.title')}</div>
                    <div className="row mt-5 margin-cero-padding-cero">
                        <div className="col-6 col-md-6 margin-cero-padding-cero">
                            <dt>{t('locationComponent.location')}</dt>
                            <p className="text-link regular" style={{textTransform: 'uppercase'}}>{data.location_name}</p>
                        </div>
                        <div className="col-6 col-md-6 text-md-end margin-cero-padding-cero">
                            <dt>{t('transactionsCustomer.invoicedetail_value')}</dt>
                            <p className="text-link regular">{formatCurrency(data.order_value)}</p>
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#595959', height: 1}}></div>
                    <div className="mt-3">
                        {data.tx_method == 'CASH'?
                        [
                            { label: t('transactionsComponent.code'), value: data.tx_code },
                            { label: t('ordersComponent.order'), value: data.order_consecutive },
                            { label: t('transactionsComponent.processor'), value: data.tx_processor },
                            { label: t('transactionsComponent.transaction_status'), value: data.tx_transaction_status },
                            { label: t('transactionsComponent.transaction_id'), value: data.tx_transaction_id },
                            { label: t('transactionsComponent.method'), value: data.tx_method },
                            { label: t('transactionsComponent.amount'), value: formatCurrency(data.tx_amount) },
                        ].map((item, index) => (
                            <div className="row mb-2" key={index}>
                                <div className="col-6 col-md-6 column-dt">
                                    <dt>{item.label}</dt>
                                </div>
                                <div className="col-6 col-md-6 text-md-end column-dd">
                                    <dt>{item.value}</dt>
                                </div>
                            </div>
                        )):
                        [
                            { label: t('transactionsComponent.code'), value: data.tx_code },
                            { label: t('ordersComponent.order'), value: data.order_consecutive },
                            { label: t('transactionsComponent.order_clover'), value: data.tx_order_id_clover },
                            { label: t('transactionsComponent.payment_colver'), value: data.tx_payment_id_clover },
                            { label: t('transactionsComponent.processor'), value: data.tx_processor },
                            { label: t('transactionsComponent.transaction_status'), value: data.tx_transaction_status },
                            { label: t('transactionsComponent.transaction_id'), value: data.tx_transaction_id },
                            { label: t('transactionsComponent.method'), value: data.tx_method },
                            { label: t('transactionsComponent.card'), value: '**** **** **** ' + card.last4 },
                            { label: t('transactionsComponent.amount'), value: formatCurrency(data.tx_amount) },
                        ].map((item, index) => (
                            <div className="row mb-2" key={index}>
                                <div className="col-6 col-md-6 column-dt">
                                    <dt>{item.label}</dt>
                                </div>
                                <div className="col-6 col-md-6 text-md-end column-dd">
                                    <dt>{item.value}</dt>
                                </div>
                            </div>
                        ))}
                    </div>

                    {
                        loader &&
                        <>
                            <div className="content-loader">
                                <CircularProgress />
                            </div>
                        </>
                    }
                </div>
                <button className="download-button d-flex mt-3" onClick={handleDownloadPDF}>
                    <span className="material-symbols-outlined">download</span>
                    <p><a className="text-linkSub regular">{t('transactionsComponent.download')}</a></p>
                </button>
            </div>
        </div>
    )
}
export default ViewTransaction;