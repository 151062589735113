import './../../assets/css/App.css';
import Widget from '../molecules/Widget';
import { useFetch } from '../../hooks/useFecth';
import { useContext, useEffect, useState } from 'react';
import HandlerApp from '../../utils/handlerApp';
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import "../../components/templates/App.css";
import "../../components/templates/AppDark.css";
import "../../components/templates/variables.css";
import BarGraph from './BarGraph';
import { Logout } from '../../utils/BoldLogin';
import { useFirebase } from '../../hooks/useFirebase';
import { collection, getDocs, doc, getDoc, query, addDoc, onSnapshot, where } from "firebase/firestore";
import { db } from '../../firebase/config';
import { AppContext } from "../../context/AppContext";
import NoDataTables from '../noDataTable/NoDataTables';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';

function Home() {
  const endpoint = "administration/home";
  const endpointUserCompany = "configuration/userscompanies_pin";

  const { register, handleSubmit, formState: { errors }, clearErrors: clearErrorsCard, setValue, control,reset } = useForm()


  const [data, setData] = useState();
  const handlerApp = HandlerApp();
  const api = useFetch();
  const [t] = useTranslation("global");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [action, setAction] = useState(localStorage.getItem("action"));
  const logout = Logout();
  const [dataX, setDataX] = useState([]);
  const [dataY, setDataY] = useState([]);
  const [hasPin, setHasPin] = useState();
  const [modalPinCode, setModalPinCode] = useState(false)
  const { modeTheme } = useContext(AppContext);
  const profile = handlerApp.getProfile()

  const [formData, setFormData] = useState({
    usercompany_pincode: "",
});

  const [locationId, setLocationId] = useState();

  const columnsProducts = [
    {
      id: 'product_name',
      name: t('productComponent.product'),
      selector: row => row.product_name,
      sortable: true,
    },
    {
      id: 'total_vendido',
      name: t('totalSold'),
      selector: row => handlerApp.formatCurrency(row.total_vendido),
      sortable: true
    }
  ];

  const darkTheme = {
    // Dark theme options
    backgroundColor: 'transparent', // Dark background
    color: ['#0DC8E2'], // Primary text color
    textStyle: {
      color: '#fff', // Text color for labels, etc.
    },
    xAxis: {
      axisLabel: {
        color: '#fff', // X-axis label color
      },
      axisLine: {
        lineStyle: {
          color: '#f0f0f0', // X-axis line color
        },
      },
    },
    yAxis: {
      axisLabel: {
        color: '#fff', // Y-axis label color
      },
      axisLine: {
        lineStyle: {
          color: '#fff', // Y-axis line color
        },
      },
    },
    title: {
      textStyle: {
        color: '#fff', // Legend text color
      },
    },
    tooltip: {
      textStyle: {
        color: '#fff', // Color de texto del tooltip
      },
      backgroundColor: '#333', // Color de fondo del tooltip
      borderColor: '#555', // Color del borde del tooltip
    },
  };

  const lightTheme = {
    // Light theme options (optional, for reference)
    backgroundColor: 'transparent',  // Primary text color
  };

  const columnsCompanies = [
    {
      id: 'company_name',
      name: t('companyComponent.company'),
      selector: row => row.company_name,
      sortable: true,
    },
    {
      id: 'total_vendido',
      name: t('totalSold'),
      selector: row => handlerApp.formatCurrency(row.total_vendido),
      sortable: true
    }
  ];


    useEffect(() => {
        handlerApp.setLoader(true);
        if (localStorage.getItem("user") === null && localStorage.getItem("token") === null && localStorage.getItem("username") === null) {
        logout.closeSession();
        } else {
        if (localStorage.getItem("numberInformation") !== null) {
            setLocationId(handlerApp.decode(localStorage.getItem("numberInformation")));
        }
        if (localStorage.getItem("havePinCode") !== null) {
            setHasPin(localStorage.getItem("havePinCode"));
        }

        sendRequest()
        }
    }, []);


    const sendRequest = async () => {
        try {
        const response = await api.get(endpoint);
        if (response.success) {
            const {
            amount_user,
            company_data,
            transaction_data,
            daily_income,
            top_ten_companies,
            top_ten_products,
            daily_transactions,
            week_income,
            week_income_graph,
            week_ts
            } = response.data[0];
            localStorage.getItem("profile") === '1' || localStorage.getItem("profile") === '2' ?
            setData({
                amount_user: JSON.parse(amount_user),
                company_data: JSON.parse(company_data),
                transaction_data: JSON.parse(transaction_data),
                daily_income: JSON.parse(daily_income),
                top_ten_companies: JSON.parse(top_ten_companies),
                top_ten_products: JSON.parse(top_ten_products),
                daily_transactions: JSON.parse(daily_transactions),
                week_income: JSON.parse(week_income),
                week_ts: JSON.parse(week_ts),
            }) : localStorage.getItem("profile") === '3' ?
                setData({
                amount_user: JSON.parse(amount_user),
                company_data: null,
                transaction_data: JSON.parse(transaction_data),
                daily_income: JSON.parse(daily_income),
                top_ten_companies: null,
                top_ten_products: JSON.parse(top_ten_products),
                daily_transactions: JSON.parse(daily_transactions),
                week_income: JSON.parse(week_income),
                week_ts: JSON.parse(week_ts),
                }) : localStorage.getItem("profile") === '4' || localStorage.getItem("profile") === '5' ?
                setData({
                    amount_user: JSON.parse(amount_user),
                    company_data: null,
                    transaction_data: JSON.parse(transaction_data),
                    daily_income: JSON.parse(daily_income),
                    top_ten_companies: null,
                    top_ten_products: JSON.parse(top_ten_products),
                    daily_transactions: JSON.parse(daily_transactions),
                    week_income: JSON.parse(week_income),
                    week_ts: JSON.parse(week_ts),
                }) : setData({
                    amount_user: null,
                    company_data: null,
                    transaction_data: null,
                    daily_income: null,
                    top_ten_companies: null,
                    top_ten_products: null,
                    daily_transactions: null,
                    week_income: null,
                    week_ts: null,
                })

            handlerApp.setLoader(false);
            const dataDate = [];
            const arrCantidad = [];
            JSON.parse(week_income_graph).forEach(({ cantidad, fecha }) => {
            dataDate.push(getFormatDate(fecha));
            arrCantidad.push(cantidad);
            });
            setDataX(dataDate);
            setDataY(arrCantidad);
        } else {
            handlerApp.handlerResponse(response);
            handlerApp.setLoader(false);
        }
        } catch (error) {
        handlerApp.showError();
        handlerApp.setLoader(false);
        }
    }

    const getFormatDate = (date) => {
        const days = ["Sun / Dom", "Mon / Lu", "Tue / Mar", "Wed / Mié", "Thu / Ju", "Fri / Vi", "Sat / Sá"];
        const partes = date.split('-').map(part => parseInt(part, 10));
        const objDate = new Date(partes[0], partes[1] - 1, partes[2]);

        return `${days[objDate.getDay()]} ${objDate.getDate()}`;
    }

    useEffect(() => {
        if (hasPin === "false" && profile == 3) {
            setModalPinCode(true)
        }
    }, [hasPin])

    const handleChangeData = (e) => {
        let key =  e.target.name;
        let value = e.target.value;
        if (key === 'usercompany_pincode') {
            if (/^\d*$/.test(value)) {
                setFormData({
                    ...formData,
                    [key]: e.target.value
                });
            }
        } else {
            setFormData({
                ...formData,
                [key]: e.target.value
            });
        }

        setValue(key, e.target.value);
    }

  const handleForm = (data) => {
    handlerApp.setLoader(true);
    api.post(endpointUserCompany, data)
      .then(response => {
        if (response.success) {
          handlerApp.showOk(response);
          handlerApp.setLoader(false);
          setModalPinCode(false)
          setHasPin("true")
          localStorage.setItem("havePinCode", "true")
        } else {
          handlerApp.handlerResponse(response);
          handlerApp.setLoader(false);
        }
      })
      .catch(error => {
        handlerApp.showError();
        handlerApp.setLoader(false);
      })
  }
  
  const handleCancelModal = () => {
    setModalPinCode(false)
    reset()
  }

  return (
    <div className="app">
      <div className='row d-flex justify-align-content-between'>
        <div className='col-xl-6 col-lgs col-md-6'>
          <Widget
            idWidget="1"
            title={t('totalUser') }
            icon="person"
            value={data?.amount_user?.total_users || "0"}
            compareText={t('newUsers')}
            compareValue={data?.amount_user?.total_users ? `${data.amount_user.new_users_last_week}` : "0"}
            compareColor={data?.amount_user?.total_users ? (data.amount_user.new_users_last_week > 0 ? "green" : "red") : "0"}
          />
        </div>
        {localStorage.getItem("profile") === '1' || localStorage.getItem("profile") === '2' ?
          <div className='col-xl-6 col-lgs col-md-6'>
            <Widget
              idWidget="2"
              title={t('totalCompanies')}
              icon="store"
              value={data?.company_data?.total_companies || "0"}
              compareText={t('newCompanies')}
              compareValue={data?.company_data?.total_companies > 0 ? `${data.company_data.new_companies_last_week}` : "0"}
              compareColor={data?.company_data?.total_companies > 0 ? (data.company_data.new_companies_last_week > 0 ? "green" : "red") : "0"}
            />
          </div> : ''}

        <div className='col-xl-6 col-lgs col-md-6'>
          <Widget
            idWidget="3"
            title={t('daily_transactions')}
            icon="browse_activity"
            value={data?.transaction_data?.total_daily_transactions > 0 ? data.transaction_data.total_daily_transactions : "0"}
            compareText={t('percentage_increase')}
            compareValue={data?.transaction_data?.total_daily_transactions > 0 ? `${data.transaction_data.percentage_increase}%` : "0"}
            compareColor={data?.transaction_data?.total_daily_transactions > 0 ? (data.transaction_data.percentage_increase.percentage_increase > 0 ? "green" : "red") : "0"}
          />
        </div>
        <div className='col-xl-6 col-lgs col-md-6'>
          <Widget
            idWidget="4"
            title={t('daily_income')}
            icon="attach_money"
            value={data?.transaction_data?.total_daily_income > 0 ? handlerApp.formatCurrency(data.transaction_data.total_daily_income) : "0"}
            compareText={t('weekly_income')}
            compareValue={data?.transaction_data?.total_daily_income > 0 ? `${handlerApp.formatCurrency(data.transaction_data.total_last_week_income)}` : "0"}
            compareColor={data?.transaction_data?.total_daily_income > 0 ? (data.transaction_data.total_last_week_income > 0 ? "green" : "red") : "0"}
          />
        </div>

        <div className="">
          <div className=" col-xl-12 col-lg-12 col-md-12 mt-3">
            <div className='container bold-container-content'>

            <BarGraph
              dataX={dataX}
              dataY={dataY}
              theme={modeTheme ? darkTheme : lightTheme }
            />
            </div>
          </div>
        </div>

        <div className="">
          <div className='col-xl-12 col-lg-12 col-md-12 mt-3'>
            <div className='container'>
              <div className="container bold-container-content">
                <div className='row'>
                  <div className='col-md-12'>
                    <h1>{t('transactionsComponent.transactions')}</h1>
                  </div>
                  <div className='col-md-6 text-center mt-3'>
                    <strong>{t('daily_transactions')}</strong>
                    <h2>{data?.daily_transactions[0].total}</h2>
                    <dd className='m-0 subtitle'>{t('lastWeek')}</dd>
                    <dd className='subtitle'>{data?.week_income[0].total}</dd>
                  </div>
                  <div className='col-md-6 text-center mt-3 subtitle'>
                    <strong>{t('daily_income')}</strong>
                    <h2>{handlerApp.formatCurrency(data?.daily_income[0].total)}</h2>
                    <dd className='m-0 subtitle'>{t('lastWeek')}</dd>
                    <dd className='subtitle'>{(handlerApp.formatCurrency(data?.transaction_data?.total_last_week_income))}</dd>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {localStorage.getItem("profile") === '1' || localStorage.getItem("profile") === '2' ?
            <div className='col-xl-12 col-lg-12 col-md-12 mt-3'>
              <div className="container">
                <div className="container bold-container-content">
                  <div className="container-table">
                    <div className="bold-header-table">
                      <h3>{`Top 10 ${t('companyComponent.companies')}`}</h3>
                    </div>
                    <DataTable
                      columns={columnsCompanies}
                      data={data?.top_ten_companies}
                      pagination={false}
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      
                      
                    />
                  </div>
                </div>
              </div>
            </div>
            :
            <div className='col-xl-12 col-lg-12 col-md-12 mt-3'>
              <div className="container">
                <div className="container bold-container-content">
                  <div className="container-table">
                    <div className="bold-header-table">
                      <h3>{`Top 10 ${t('productComponent.products')}`}</h3>
                    </div>
                    <DataTable
                      columns={columnsProducts}
                      data={data?.top_ten_products}
                      pagination={false}
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      noDataComponent={<NoDataTables />}
                      
                    />
                  </div>
                </div>
              </div>
            </div>}
        </div>

      </div>
      <Modal
        isOpen={modalPinCode}
        className="col-md-12 card-content-modal swal2-popup"
        style={{
            overlay: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            },
        }}>
            <form onSubmit={handleSubmit(handleForm)} noValidate className='col-12 d-flex flex-column align-items-center justify-content-center py-4 px-4'>
                <h2 className="title-section-sub mt-2">{t('userCompanyComponent.userCompanyPinCode')}</h2>
                <div className="col-11">
                    <TextField
                            name="usercompany_pincode"
                            label={t("usersLocationsComponent.userlocation_pincode")}
                            variant="standard"
                            size="small"
                            {...register("usercompany_pincode", {
                                required: true,
                                minLength: 4
                            })}
                            value={formData.usercompany_pincode ?? ''}
                            onChange={handleChangeData}
                            error={errors.usercompany_pincode != null}
                            inputProps={{
                                maxLength: 4,
                            }}
                            helperText={
                                errors.usercompany_pincode?.type === 'required'
                                    ? t('validationErrorMessages.required') : 
                                errors.usercompany_pincode?.type === 'minLength'
                                    ? t("validationErrorMessages.short")+4 : ""
                            }
                            required
                        />
                </div>
                <center className="col-12 d-flex gap-3 justify-content-center mt-5">
                    <Button className="col-4 swal2-styled swal2-cancel" onClick={() => handleCancelModal()} >{t('cancel')}</Button>
                    <Button className="col-4 swal2-styled swal2-confirm" type="submit">{t('myAccounts.save')}</Button>
                </center>
            </form>
        </Modal>
    </div>
  );
}

export default Home;