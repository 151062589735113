
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useEffect, useState, useContext } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import moment from "moment/moment";
import HandlerApp from "../../../utils/handlerApp";
import BoldInputPhone from "../../atoms/Inputs/BoldInputPhone";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";

const UsersForms = () => {

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const endpoint = "administration/users";
    const [t, i18n] = useTranslation("global");

    const api = useFetch();
    const [data, setData] = useState([]);

    const nav = useNavigate();
    const { id } = useParams();
    const [locationRequired, setLocationRequired] = useState(false);

    const [birthdate, setBirthdate] = useState(null);
    const [showCompany, setShowCompany] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [showPinCompany, setShowPinCompany] = useState(false);
    const [showPosition, setShowPosition] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [formData, setFormData] = useState({
        user_username: "",
        company_id: "",
        location_id: "",
        userlocation_id: "",
        userlocation_pincode: "",
        usercompany_pincode: "",
        person_id: "",
        profile_id: "",
        person_firstname: "",
        person_secondname: "",
        person_surname: "",
        person_secondsurname: "",
        person_sex: "",
        person_email: "",
        person_phone: "",
        person_ext: "",
        person_birthdate: "",
        person_occupation: "",
        person_marital_status: "",
        userlocation_position: "",
    });

    const [locations, setLocations] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState("");
    const [sex, setSex] = useState([]);
    const [sexFilter, setSexFilter] = useState([]);
    const [maritalFilter, setMaritalFilter] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [marital, setMarital] = useState([]);
    const [disabledCL, setDisabledCL] = useState(false);
    const [disabledProfile, setDisabledProfile] = useState(false);

    // Permisos
    const handlerApp = HandlerApp();
    const profile = localStorage.getItem("profile")
    const [permissions, setPermissions] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const { app } = useContext(AppContext);
    useEffect(() => {
        if (birthdate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, person_birthdate: birthdate?.format('YYYY-MM-DD') });
            setValue("person_birthdate",birthdate?.format('YYYY-MM-DD'))
        }
    }, [birthdate]);

    useEffect(() => {
        setShowCompany(false);
        setShowLocation(false);
        setShowPosition(false);

        if (formData.profile_id != "" && formData.profile_id == 3) {
            setShowPinCompany(true);
            setShowCompany(true); // muestro el campo de empresa.
        }
        if (formData.profile_id != "" && formData.profile_id >= 4) {
            setShowCompany(true);
            setShowLocation(true);
            setShowPinCompany(false);

        }
        if (formData.profile_id !== "" && formData.profile_id === 4) {
            setShowPosition(true);
            if (id === undefined || formData.userlocation_position === null) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    userlocation_position: 'Manager'
                }));
            }
        }
        if (formData.profile_id !== "" && formData.profile_id === 5) {
            setShowPosition(true);
            if (id === undefined) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    userlocation_position: ""
                }));
            }        
        }

    }, [formData.profile_id]);

    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        setTitle(t('personComponent.createUser'));
        setDescription(t('description.user_create'));
        if (id !== undefined) {
            setTitle(t('personComponent.updateUser'));
            setDescription(t('description.user_update'));
        }
    }, [t])

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        if (d.person_birthdate != null) {
            setBirthdate(moment(d.person_birthdate));
        }
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];

            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    if (data.profile_id >= 3) {
                        setLocationRequired(true)
                        setCompany(data.company_id);
                        setDisabledCL(true)
                    }else{
                        setDisabledCL(false)
                    }
                    
                    const idUser = handlerApp.getUser();
                    if(response.data[0]?.user_id == idUser){
                        setDisabledProfile(true)
                    } else if (response.data[0]?.profile_id == 1 && profile == 2) {
                        setDisabledProfile(true);
                    }
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                    setDisableButton(false)
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError();
            })
    }

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
            .then(response => {
                if (response.success) {
                    setData(response.data)
                    setCompanies(handlerApp.getResultFromObject(response.data, 'companies'));
                    setLocations(handlerApp.getResultFromObject(response.data, 'locations'));
                    setSex(handlerApp.getResultFromObject(response.data, 'sex'));
                    setProfiles(handlerApp.getResultFromObject(response.data, 'profiles'));
                    setMarital(handlerApp.getResultFromObject(response.data, 'marital'));
                    if (id !== undefined) {
                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError();
            })
    }, [])
    useEffect(() => {
        if(sex.length > 0) {
            if(i18n.language =="en"){
                const nameSex = sex.map(item => ({
                    code: item.code,
                    name: item.name,
                  }));
                setSexFilter(nameSex)
            }
            else {
                const nameSex = sex.map(item => {
                    return {
                      code: item.code,
                      name: item.namespanish,
                    };
                  });
                setSexFilter(nameSex)
            }
        }
    }, [sex, i18n.language])

    useEffect(() => {
        if(marital.length > 0) {
            if(i18n.language =="en"){
                const nameMarital = marital.map(item => ({
                    code: item.code,
                    name: item.name,
                  }));
                setMaritalFilter(nameMarital)
            }
            else {
                const nameMarital = marital.map(item => {
                    return {
                      code: item.code,
                      name: item.namespanish,
                    };
                  });
                setMaritalFilter(nameMarital)
            }
        }
    }, [marital, i18n.language])

    const handlePhone = (value, name) => {
        setValue(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(()=>{
        if(profile>=3){
            handlerApp.setLoader(true);
                api.get("administration/users")
                .then(response => {
                    if(response.success){
                        setCompany(response.data[0].company_id)
                        handlerApp.setLoader(false);
                    }else{
                        handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
                })
                .catch(error => {
                    handlerApp.showError();
                    handlerApp.setLoader(false);
                })
            }
        
    },[])

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        let value = e.target.value;

        if (key === 'userlocation_pincode' || key === 'usercompany_pincode') {
            if (/^\d*$/.test(value)) {
                setFormData({
                    ...formData,
                    [key]: e.target.value
                });
            }
        } else {
            setFormData({
                ...formData,
                [key]: e.target.value
            });
        }

        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitLocal = async (data) => {
        if(formData.profile_id===3){
            handlerApp.setLoader(true);
            Swal.fire({
                icon: 'info',
                title: `${t('personComponent.Alert')}`,
                text:`${t('personComponent.AlertBodyCreate')}`,
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `${t('myAccounts.confirm')}`,
                confirmButtonColor: "green",
                cancelButtonText: `${t('cancel')}`
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        data.user_username = data.person_email.toLowerCase()
                        let body = {
                            ...data, 
                            company_id: company,
                            location_id: formData.location_id
                        }
                        await api.post(endpoint, body)
                            .then(response => {
                                if (response.success) {
                                    handlerApp.showOk(response);
                                    handlerApp.setLoader(false);
                                    nav("/" + endpoint);
                                } else {
                                    handlerApp.handlerResponse(response);
                                    handlerApp.setLoader(false);
                                }

                            })
                            .catch(error => {
                                handlerApp.showError(error);
                                handlerApp.setLoader(false);
                            })
                    }else {
                        handlerApp.setLoader(false);
                    }
                });
        }else{  
            handlerApp.setLoader(true);
            data.user_username = data.person_email.toLowerCase()
            let body = {
                ...data, 
                company_id: company,
                location_id: formData.location_id
            }
            await api.post(endpoint, body)
                .then(response => {
                    if (response.success) {
                        handlerApp.showOk(response);
                        handlerApp.setLoader(false);
                        nav("/" + endpoint);
                    } else {
                        handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
    
                })
                .catch(error => {
                    handlerApp.showError(error);
                    handlerApp.setLoader(false);
                })
        }

    }

    const handleUpdate = async(data) => {
        if(formData.profile_id===3){
            Swal.fire({
                icon: 'info',
                title: `${t('personComponent.Alert')}`,
                text:`${t('personComponent.AlertBodyEdit')}`,
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `${t('myAccounts.confirm')}`,
                confirmButtonColor: "green",
                cancelButtonText: `${t('cancel')}`
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        data.user_username = data.person_email.toLowerCase()
                        let body = {
                            ...data, 
                            company_id: company,
                        }
                        handlerApp.setLoader(true);
                        await api.put(endpoint + "/" + id, body)
                            .then(response => {
                                if (response.success) {
                                    handlerApp.showOk(response);
                                    handlerApp.setLoader(false);
                                    nav("/" + endpoint);
                                } else {
                                    handlerApp.handlerResponse(response);
                                    handlerApp.setLoader(false);
                                }
                            })
                            .catch(error => {
                                handlerApp.showError(error);
                                handlerApp.setLoader(false);
                            })
                    }
                });
        }else{
            data.user_username = data.person_email.toLowerCase()
            let body = {
                ...data, 
                company_id: company,
            }
            handlerApp.setLoader(true);
            await api.put(endpoint + "/" + id, body)
                .then(response => {
                    if (response.success) {
                        handlerApp.showOk(response);
                        handlerApp.setLoader(false);
                        nav("/" + endpoint);
                    } else {
                        handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
                })
                .catch(error => {
                    handlerApp.showError(error);
                    handlerApp.setLoader(false);
                })
        }
    }

    const handleSelectLocation = (val) => {
        setCompany(val);
        setLocations([]);
    }

    const handleSelectProfile = (val) => {
        if (val >= 4) {
            getLocations()
            setLocationRequired(true);
        }
    }

    const getLocations = () => {
        if ((company)) {
            handlerApp.setLoader(true);
            api.get(endpoint, {
                action: "getLocation",
                company: company,

            })
                .then(response => {
                    if (response.success) {
                        setLocations(response.data);
                        handlerApp.setLoader(false);
                    } else {
                        handlerApp.setLoader(false);
                        handlerApp.handlerResponse(response);
                    }
                })
                .catch(error => {
                    handlerApp.showError(error);
                })
        }
    }

    useEffect(() => {
        getLocations();
    }, [company])
    

    useEffect(() => {
        if (formData.profile_id >= 4) {
            setCompany(formData.company_id)
        }
    }, [formData.profile_id])

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description}</p>
            </div>

            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('profile') + " *"}
                                value={formData.profile_id ?? ''}
                                name="profile_id"
                                options={profiles}
                                onChange={handleForm}
                                register={register}
                                errors={errors}
                                required={true}
                                dependency={handleSelectProfile}
                                disable={disabledProfile}
                            />
                        </div>
                        {profile<=2 &&
                            showCompany && (
                                <div className="col-md-6 mb-2">
                                    <BoldSelectValidated
                                        title={t('companyComponent.company') + " *"}
                                        value={formData.company_id ?? ''}
                                        name="company_id"
                                        options={companies}
                                        onChange={handleForm}
                                        register={register}
                                        errors={errors}
                                        required={true}
                                        dependency={handleSelectLocation}
                                        disable={disabledCL}
                                    />
                                </div>
                            )
                        }

                        {showPinCompany && (
                            <div className="col-md-6 mb-2">
                                <TextField
                                    name="usercompany_pincode"
                                    label={t("usersLocationsComponent.userlocation_pincode")}
                                    variant="standard"
                                    size="small"
                                    {...register("usercompany_pincode", {
                                        minLength: 4
                                    })}
                                    value={formData.usercompany_pincode ?? ''}
                                    onChange={handleForm}
                                    error={errors.usercompany_pincode != null}
                                    inputProps={{
                                        maxLength: 4,
                                    }}
                                    helperText={
                                        errors.usercompany_pincode?.type === 'minLength'
                                            ? t("validationErrorMessages.short")+4 : ""
                                    }
                                />
                                <div className="description-input">{t('userCompanyComponent.userCompanyPinCode')}</div>
                            </div>
                        )}

                        {showLocation && (
                            <>
                                <div className="col-md-6 mb-2">
                                    <BoldSelectValidated
                                        title={t('locationComponent.location') + " *"}
                                        value={formData.location_id ?? ''}
                                        name="location_id"
                                        options={locations}
                                        onChange={handleForm}
                                        register={register}
                                        errors={errors}
                                        required={locationRequired}
                                    />
                                </div>

                                <div className="col-md-6 mb-2">
                                    <TextField
                                        name="userlocation_pincode"
                                        label={t("usersLocationsComponent.userlocation_pincode")}
                                        variant="standard"
                                        size="small"
                                        {...register("userlocation_pincode", {
                                            required: true,
                                            minLength: 4
                                        })}
                                        value={formData.userlocation_pincode ?? ''}
                                        onChange={handleForm}
                                        error={errors.userlocation_pincode != null}
                                        inputProps={{
                                            maxLength: 4,
                                        }}
                                        helperText={
                                            errors.userlocation_pincode?.type === 'required'
                                                ? t('validationErrorMessages.required') : 
                                            errors.userlocation_pincode?.type === 'minLength'
                                                ? t("validationErrorMessages.short")+4 : ""
                                        }
                                        required
                                    />
                                </div>
                            </>
                        )}
                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('personComponent.firstName') + " *"} variant="standard" size="small"
                                {...register("person_firstname", { required: true, maxLength: 80 })}
                                value={formData.person_firstname ?? ''}
                                onChange={handleForm}
                                error={errors.person_firstname != null}
                                helperText={
                                    errors.person_firstname?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.person_firstname?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('personComponent.secondName')} variant="standard" size="small"
                                {...register("person_secondname", { maxLength: 80 })}
                                value={formData.person_secondname ?? ''}
                                onChange={handleForm}
                                error={errors.person_secondname != null}
                                helperText={

                                    errors.person_secondname?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('personComponent.surName') + " *"} variant="standard" size="small"
                                {...register("person_surname", { required: true, maxLength: 80 })}
                                value={formData.person_surname ?? ''}
                                onChange={handleForm}
                                error={errors.person_surname != null}
                                helperText={
                                    errors.person_surname?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.person_surname?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('personComponent.secondSurname')} variant="standard" size="small"
                                {...register("person_secondsurname", { maxLength: 80 })}
                                value={formData.person_secondsurname ?? ''}
                                onChange={handleForm}
                                error={errors.person_secondsurname != null}
                                helperText={

                                    errors.person_secondsurname?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('personComponent.sex')}
                                value={formData.person_sex ?? ''}
                                name="person_sex"
                                options={sexFilter}
                                onChange={handleForm}
                                register={register}
                                errors={errors}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('email') + " *"}
                                variant="standard"
                                size="small"
                                {...register('person_email', {
                                    required: true,
                                    maxLength: 80,
                                    pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/i, // Email format regex
                                })}
                                value={formData.person_email ?? ''}
                                onChange={handleForm}
                                error={errors.person_email != null}
                                helperText={
                                    errors.person_email?.type === 'required'
                                        ? t('validationErrorMessages.required')
                                        : errors.person_email?.type === 'maxLength'
                                            ? `${t('validationErrorMessages.long')} 80 ${t('validationErrorMessages.longSuffix')}`
                                            : errors.person_email?.type === 'pattern'
                                                ? t('validationErrorMessages.invalidEmailFormat')
                                                : ''
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldInputPhone
                                name={"person_phone"}
                                label={t('phone') + " *"}
                                register={register}
                                errors={errors.person_phone}
                                onChange={handlePhone}
                                data={formData.person_phone}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField type="number" multiline={true} onInput={(e) => {
                                if (!isNaN(parseInt(e.target.value))) {
                                    e.target.value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 4);
                                }else{
                                    e.target.value = '' 
                                }
                            }} name="person_ext" onChange={handleForm} value={formData.person_ext ?? ''} label={t('ext')} variant="standard" size="small" />
                        </div>

                        <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('personComponent.birthdate')}
                                format="YYYY/MM/DD"
                                name="person_birthdate"
                                variant="standard"
                                onChange={(val) => { setBirthdate(val); setValue('person_birthdate',val) }}
                                value={birthdate}
                                disableFuture={true}
                                slotProps={{ textField: { size: 'small', variant: "standard" } }}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('occupation')} variant="standard" size="small"
                                {...register("person_occupation", { maxLength: 120 })}
                                value={formData.person_occupation ?? ''}
                                onChange={handleForm}
                                error={errors.person_occupation != null}
                                helperText={

                                    errors.person_occupation?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 120 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('personComponent.marital')}
                                value={formData.person_marital_status ?? ''}
                                name="person_marital_status"
                                options={maritalFilter}
                                onChange={handleForm}
                                register={register}
                                errors={errors}
                            />
                        </div>
                        { showPosition &&

                        <div className="col-md-6 mb-2">
                            <TextField label={t("locationComponent.position")} variant="standard" size="small"
                                {...register("userlocation_position", { maxLength: 80 })}
                                value={formData.userlocation_position ?? ''}
                                onChange={handleForm}
                                error={errors.userlocation_position != null}
                                helperText={
                                    errors.userlocation_position?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />     
                        </div>
                        }

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UsersForms;
