import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";

const PrintersView = () =>{
    
    const endpoint = 'configuration/printers';
    const [data, setData] = useState([]);
    const api = useFetch();
    const {id} = useParams();
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const [t,i18n] = useTranslation("global");
    const [title, setTitle] = useState('');    

    useEffect(()=>{
        sendRequest();
    },[]);

    const sendRequest = () =>{
        handlerApp.setLoader(true);
        api.get(endpoint + '/'+ id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let record = response.data[0];
                setData(record);
                setTitle(record.printer_name);
            }
            else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }
    const back = ()=>{
        nav(-1);
    }
    const printActive = () =>{
        if(data.printer_active == 1){
            return 'Activo';
        }else {
            return 'Inactivo';
        }
    }
    return (
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row">
                <div className="col-md-6 mb-2">
                        <dt>{t('printersComponent.printer')}</dt>
                        <dd>{data.printer_name}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('locationComponent.location')}</dt>
                        <dd>{data.location_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('printersComponent.state')} </dt>
                        <dd>{printActive() || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('alarmsConfigurationComponent.alarmConfigurationPickup')} ({t('minutes')})</dt>
                        <dd>{data.print_pickup || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('alarmsConfigurationComponent.alarmConfigurationTimeDelay')} ({t('minutes')})</dt>
                        <dd>{data.print_time_delay || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNearDelay')} ({t('minutes')})</dt>
                        <dd>{data.print_time_limit_near_delay || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    <div className="col-md-6 mb-2">
                        <dt>{t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNormal')} ({t('minutes')})</dt>
                        <dd>{data.print_time_limit_normal || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                    </div>
                    
                    
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.printer_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.printer_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.printer_modified_at !== null && data.printer_modified_at !== undefined && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.printer_modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrintersView;