import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import imageNotFound from "../../../assets/img/image-not-found.webp"

const ProductLocation2View = () => {

    const endpoint = 'configuration/locations/products';
    const [data, setData] = useState([]);
    const api = useFetch();
    const { id } = useParams();
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const [t, i18n] = useTranslation("global");
    const [title, setTitle] = useState('');

    useEffect(() => {
        sendRequest();
    }, []);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + '/' + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let record = response.data[0];
                    setData(record);
                    setTitle(record.product_name);
                }
                else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }
    const back = () => {
        nav(-1);
    }
    return (
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row np-row">
                    { (data.images === "" || data.images === undefined || data.images === null) && 
                        <>
                        <div className="col-md-4 d-flex mb-4">
                            <section className="section-image-view" style={{backgroundImage: `url('${imageNotFound}')`}}>
                            </section>
                        </div>
                        </>
                    }
                    { data.images !== "" && data.images !== undefined && data.images !== null &&
                        <>
                        <div className="col-md-4 d-flex mb-4">
                            <section className="section-image-view" style={{backgroundImage: `url('${data.images}')`}}>
                            </section>
                        </div>
                        </>
                    }
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.product')}</dt>
                                <dd>{data.product_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('locationComponent.location')}</dt>
                                <dd>{data.location_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.price')} </dt>
                                {data.productlocation_discount > 0 &&<dd className="margin-cero-padding-cero">{ handlerApp.formatCurrency(data.product_price-(data.product_price*(data.productlocation_discount/100)))}</dd>}
                                <dd className="margin-cero-padding-cero" style={{textDecorationLine: data.productlocation_discount > 0 ? 'line-through' :'none', textDecorationColor:data.productlocation_discount > 0 ?'red': 'transparent'}}>{ handlerApp.formatCurrency(data.product_price)}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('productComponent.discount')} </dt>
                                <dd>{ handlerApp.formatPercent(data.productlocation_discount) || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('productLocationComponent.stock')}</dt>
                                <dd>{data.productlocation_stock || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-12 mb-2">
                                {
                                    data.productlocation_not_available == true &&
                                    <>
                                        <button className="btn-table-actions wx2" title="Product not available" >
                                            <span className="material-symbols-outlined inactive">production_quantity_limits</span>
                                        </button>
                                        <span className="small-text">No disponible</span>
                                    </>
                                }

                                {
                                    data.productlocation_not_available == false &&
                                    <>
                                        <button className="btn-table-actions wx2" title="Product available" >
                                            <span className="material-symbols-outlined active">production_quantity_limits</span>
                                        </button>
                                        <span className="text-gray light small-text">Disponible</span>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductLocation2View;