import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./../assets/css/BoldFilesUpload.css";
import HandlerApp from "./handlerApp";
import Swal from 'sweetalert2';

const BoldFilesUpload = ({ returnImages, multiple, value }) => {
    const uploadSection = useRef();
    const boldInput = useRef();
    const handlerApp = HandlerApp();

    const [t, i18n] = useTranslation("global");
    const [loader, setLoader] = useState(false);
    const [formImages, setFormImages] = useState([]);

    useEffect(() => {
        if (multiple && value.length > 0) {
            value = JSON.parse(value);
        }
    }, [multiple, value]);

    useEffect(() => {
        let imageList = [];
        if (value) {
            if (multiple) {
                if (value.length > 0) {
                    value.forEach(element => {
                        imageList.push({
                            name: element.image_url,
                            src: element.image_url,
                        });
                    });
                }
            } else {
                imageList.push({
                    name: value,
                    src: value,
                });
            }
        }
        setFormImages(imageList);
        returnImages(imageList);
    }, [value]);

    const handleOnDragOver = (e) => {
        e.preventDefault();
        uploadSection.current.className = "section-bold-upload-files drag-over";
        return false;
    };

    const handleOnDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        proccessImages(files);
    };

    const handleClickAttach = (e) => {
        e.preventDefault();
        boldInput.current.click();
    };

    const onChangeUploads = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const images = e.target.files;
        proccessImages(images);
    }; 

    const proccessImages = async (filesImages) => {
        let newImages = [...formImages];
        const imagePromises = [];

        for (let i = 0; i < filesImages.length; i++) {
            const image = filesImages[i];
            imagePromises.push(buildImage(image, image.name));
        }

        try {
            const processedImages = await Promise.all(imagePromises);
            setFormImages([...newImages, ...processedImages]);
            returnImages([...newImages, ...processedImages]);
        } catch (error) {
            Swal.fire({ title: '', text: t("errorUpload.filesError"), icon: 'error' });
        }
    };

    const buildImage = (image, name) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            const picture = new Image();

            fileReader.onload = () => {
                picture.src = fileReader.result;
            };

            fileReader.onerror = reject;
            fileReader.readAsDataURL(image);

            picture.onload = () => {
                // Aquí podemos ajustar el tamaño de la imagen (compresión)
                const canvas = document.createElement('canvas');
                const maxWidth = 800; // Definir un tamaño máximo
                const scaleSize = maxWidth / picture.width;
                canvas.width = maxWidth;
                canvas.height = picture.height * scaleSize;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(picture, 0, 0, canvas.width, canvas.height);

                const compressedImage = canvas.toDataURL('image/jpeg', 0.7); // Comprimir al 70% calidad JPEG
                resolve({ name, src: compressedImage });
            };
        });
    };

    const removeItems = (e) => {
        e.preventDefault();
        boldInput.current.value = "";
        setFormImages([]);
        returnImages([]);
    };

    return (
        <>
            <div className="files-text">{t('profileImageDescription')}</div>
            <div className="container-uploads ml-5">
                <div
                    ref={uploadSection}
                    className="section-bold-upload-files"
                    onDragOver={handleOnDragOver}
                    onDrop={handleOnDrop}
                >
                    {formImages.length > 0 ? (
                        formImages.map((image, index) => (
                            <img src={image.src} key={index} alt={image.name} />
                        ))
                    ) : (
                        <div className="inner-upload">
                            <button className="bold-btn" onClick={handleClickAttach}>
                                <span className="material-symbols-outlined">attach_file</span>
                            </button>
                            <span className="title-upload">{t("dragAndDrop")}</span>
                        </div>
                    )}
                </div>
                <div className="buttons-options">
                    <button onClick={handleClickAttach}>
                        <span className="material-symbols-outlined">add</span>
                        {t("add")}
                    </button>
                    <button onClick={removeItems}>
                        <span className="material-symbols-outlined">delete_forever</span>
                        {t("remove")}
                    </button>
                </div>
                <input
                    ref={boldInput}
                    onChange={onChangeUploads}
                    hidden
                    type="file"
                    name="images[]"
                    multiple={multiple}
                    id="bold-upload-files"
                    accept=".png, .jpg, .jpeg, .webp"
                />
            </div>
        </>
    );
};

export default BoldFilesUpload;
