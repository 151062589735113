import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./../assets/css/BoldFilesUpload.css";
import imageNotFound from "../assets/img/image-not-found.webp";
import Swal from 'sweetalert2';

const BoldFilesUploadProfile = ({ returnImages, multiple, value, name }) => {

    const uploadSection = useRef();
    const boldInput = useRef();

    const [t] = useTranslation("global");

    const [loader, setLoader] = useState();
    const [formImages, setFormImages] = useState([{
        name: "Default Image",
        src: imageNotFound
    }]);

    useEffect(()=>{
        let imageList = [];
        if(value.length > 0){
            imageList.push({
                name: value,
                src: value
            })
            setFormImages(imageList)
        }
        returnImages(imageList);
    },[value]);

    const handleOnDragOver = (e) => {
        e.preventDefault();
        uploadSection.current.className = "section-bold-upload-files drag-over";
        return false;
    };

    const handleOnDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        proccessImages(files, returnImages);
    }

    const handleClickAttach = (e) => {
        e.preventDefault();
        boldInput.current.click();
    }

    const onChangeUploads = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let images = e.target.files;
        let imageList = [];
        imageList = formImages;
        proccessImages(images, returnImages, imageList);
    }

    const proccessImages = async (filesImages) => {
        const imagePromises = [];

        const image = filesImages[0];
        imagePromises.push(buildImage(image, image.name));

        try {
            const processedImages = await Promise.all(imagePromises);
            setFormImages([...processedImages]);
            returnImages([...processedImages]);
        } catch (error) {
            Swal.fire({ title: '', text: t("errorUpload.fileError"), icon: 'error' });
        }
    };

    const buildImage = (image, name) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            const picture = new Image();

            fileReader.onload = () => {
                picture.src = fileReader.result;
            };

            fileReader.onerror = reject;
            fileReader.readAsDataURL(image);

            picture.onload = () => {
                // Aquí podemos ajustar el tamaño de la imagen (compresión)
                const canvas = document.createElement('canvas');
                const maxWidth = 800; // Definir un tamaño máximo
                const scaleSize = maxWidth / picture.width;
                canvas.width = maxWidth;
                canvas.height = picture.height * scaleSize;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(picture, 0, 0, canvas.width, canvas.height);

                const compressedImage = canvas.toDataURL('image/jpeg', 0.7); // Comprimir al 70% calidad JPEG
                resolve({ name, src: compressedImage });
            };
        });
    };

    const removeItems = (e) => {
        e.preventDefault();
        boldInput.current.value = [];
        setFormImages([]);
    }
    return (
        <>
            <div className="form-username">
                <div ref={uploadSection} className="circle-name" onDragOver={handleOnDragOver} onDrop={handleOnDrop}>
                    {formImages.length > 0 &&
                        <>
                            {formImages.map((image, index) => (
                                <img src={image.src} className="profile-image" key={index} alt={index} />
                            ))}
                            <button className="bold-button-modal" onClick={handleClickAttach}>
                                <span className="material-symbols-outlined">create</span>  
                            </button>
                        </>
                    }
                    {formImages.length === 0 &&
                        <div className="">
                            {name}
                            <button className="bold-button-modal" onClick={handleClickAttach}>
                                <span className="material-symbols-outlined">create</span>  
                            </button>
                        </div>
                    }
                </div>
                <input ref={boldInput} onChange={onChangeUploads} hidden type="file" name="images[]" multiple={multiple} id="bold-upload-files"  accept=".png, .jpg, .jpeg, .webp"></input>
                <div className="files-text">{t('profileImageDescription')}</div>
            </div>
        </>
    )
}


export default BoldFilesUploadProfile;